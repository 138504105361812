<template>
  <div class="detail" v-loading="loading">
    <Breadcrumb separator="->" :list="breadcrumbList"></Breadcrumb>
    <div class="detail-box">
      <div class="detail-title">{{articleData.title}}</div>
      <div class="detail-source clearfix">
        <div v-if="articleData.source" class="pull-left">来源：{{articleData.source}}</div>
        <div class="pull-left" style="padding-left: 15px;">{{articleData.publishTime}}</div>
        <div v-if="articleData.author" class="pull-right">作者：{{articleData.author}}</div>
      </div>
      <div v-html="articleData.content" class="vhtml"></div>
      <div class="file-box">
        <ul>
          <li v-for="(item, index) of articleData.attFileList" :key="index" class="file-li">
            <div>
              <img v-if="item.type == 'pdf'" :src="pdf" alt="" class="tb-icon">
              <img v-else-if="item.type == 'ocx'" :src="ocx" alt="" class="tb-icon">
              <img v-else-if="item.type == 'lsx'" :src="lsx" alt="" class="tb-icon">
              <img v-else :src="image" alt="" class="tb-icon">
              <span class="file-title" :title="item.remark">{{item.remark}}</span>
            </div>
            <div class="file-li-icon cursor" @click="unload(item.path, item.remark)">
              <img :src="unlaodIcon" alt="">
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getDetail } from '@/api/web'
import { fileDownload } from '@/api/login.js'

export default {
  components: {},
  name: 'Detail',
  data () {
    return {
      image: require('../../../assets/images/icon_image.png'),
      pdf: require('../../../assets/images/icon_pdf.png'),
      ocx: require('../../../assets/images/icon_doc.png'),
      lsx: require('../../../assets/images/icon_xls.png'),
      unlaodIcon: require('../../../assets/images/unload_a.png'),
      loading: false,
      breadcrumbList: [
        { name: '首页', path: '/' }
      ],
      articleData: ''
    }
  },
  created () {
    this.initialData()
  },
  mounted () {},
  methods: {
    unload (path, name) {
      fileDownload(path, name)
    },
    initialData () {
      this.loading = true
      getDetail({ id: this.$route.query.articleId }).then(res => {
        this.loading = false
        if (res.code === '000') {
          const data = res.result
          const arr = data.items
          if (arr[0].url) {
            this.breadcrumbList.push({ name: arr[0].name, path: `${arr[0].url}?id=${arr[0].id}` })
          } else {
            this.breadcrumbList.push({ name: arr[0].name, path: `/list?id=${arr[0].id}` })
          }
          if (arr[1]) {
            if (arr[1].url) {
              this.breadcrumbList.push({ name: arr[1].name, path: `${arr[1].url}?id=${arr[0].id}&cid=${arr[1].id}` })
            } else {
              this.breadcrumbList.push({ name: arr[1].name, path: `/list/normal?id=${arr[0].id}&cid=${arr[1].id}` })
            }
          }
          this.breadcrumbList.push({ name: '正文', path: '' })
          if (data.attFileList !== null) {
            data.attFileList.forEach(element => {
              element.type = element.path.substr(element.path.length - 3, element.path.length - 1)
            })
          }
          this.articleData = data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.detail{
  padding-bottom: 30px;
}
.detail-box{
  padding: 40px 80px;
  min-height: 520px;
  background-color: #ffffff;
}
.detail-title{
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #E9E9E9;
}
.detail-source{
  padding-top: 10px;
  padding-bottom: 38px;
  width: 500px;
  margin: auto;
  font-size: 12px;
  color: #999999;
}
.file-box{
  padding: 20px;
}
.file-li{
  padding: 10px 65px 0 25px;
  height: 40px;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 4px;
  margin-bottom: 5px;
}
.file-li:hover{
  background-color: #f0f3fa;
}
.file-title{
  width: 90%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
}
.file-li-icon{
  height: 20px;
  width: 20px;
  position: absolute;
  top: 10px;
  right: 25px;
}
.file-li-icon img{
  display: block;
  height: 100%;
  width: 100%;
}
.tb-icon{
  height: 18px;
  width: 18px;
  margin-right: 10px;
}
</style>
